// export const DEFAULT_EVENT_BUTTON_TRIGGER_MAPPINGS = new Map([
//     ['99', 'Panic'],
//     ['21', 'Trigger 1'],
//     ['22', 'Trigger 2'],
//     ['23', 'Trigger 3'],
//     ['24', 'Trigger 4'],
//     ['25', 'Trigger 5'],
//     ['26', 'Trigger 6'],
//     ['27', 'Trigger 7'],
//     ['28', 'Trigger 8'],
// ]);

// export const DEFAULT_EVENT_G_SENSOR_MAPPINGS = new Map([
//     ['41', 'Hard Acceleration'],
//     ['42', 'Harsh Braking'],
//     ['43', 'Harsh Cornering Left'],
//     ['44', 'Harsh Cornering Right'],
// ]);

export const DEFAULT_EVENT_BUTTON_TRIGGER_MAPPINGS = {
    '118-1': { eventId: '118', channel: '1', alias: 'Trigger 1' },
    '118-2': { eventId: '118', channel: '2', alias: 'Trigger 2' },
    '118-3': { eventId: '118', channel: '3', alias: 'Trigger 3' },
    '118-4': { eventId: '118', channel: '4', alias: 'Trigger 4' },
    '118-5': { eventId: '118', channel: '5', alias: 'Trigger 5' },
    '118-6': { eventId: '118', channel: '6', alias: 'Trigger 6' },
    '118-7': { eventId: '118', channel: '7', alias: 'Trigger 7' },
    '118-8': { eventId: '118', channel: '8', alias: 'Trigger 8' },
};

export const DEFAULT_EVENT_G_SENSOR_MAPPINGS = {
    119: { eventId: '119', alias: 'Hard Acceleration' },
    120: { eventId: '120', alias: 'Harsh Braking' },
    121: { eventId: '121', alias: 'Harsh Cornering Left' },
    122: { eventId: '122', alias: 'Harsh Cornering Right' },
};

export const DEFAULT_EVENT_MAPPINGS = {
    999: { eventId: '999', alias: 'Panic' },
    ...DEFAULT_EVENT_BUTTON_TRIGGER_MAPPINGS,
    ...DEFAULT_EVENT_G_SENSOR_MAPPINGS,
};
